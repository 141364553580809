*,
*:before,
*:after {
  box-sizing: border-box;
}

:root {
  --gap-vertical: 4rem;
  --gap-horizontal: 6rem;
  --layout-narrow-part: 28rem;
  --layout-wide-part: 52rem;

  --font-size-default: max(1.6rem, 14px);
  --font-size-small: max(1.4rem, 14px);
  --font-size-larger: max(2rem, 14px);
  --font-size-heading-1: max(7.2rem, 14px);
  --font-size-heading-2: max(2.4rem, 14px);

  --font-family-default: 'Inter', sans-serif;

  --line-height-default: 1.6;
  --line-height-heading-1: 1;
  --line-height-heading-2: 1.2;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-dark: #2b2b2b;
  --color-light: #d4d4d4;
  --color-dimmed: #80808010;

  --duration-blink: 200ms;
  --duration-fast: 400ms;
  --duration-default: 800ms;
  --duration-slow: 1600ms;

  --ease-out: cubic-bezier(0.22, 1, 0.36, 1);
  --ease-in-out: cubic-bezier(0.83, 0, 0.17, 1);

  @media #{$breakpoint-tablet} {
    --gap-horizontal: 4rem;
    --layout-narrow-part: 20rem;
    --wrapper-max-width: 80rem;
  }

  @media #{$breakpoint-tablet} {
    --gap-horizontal: 3rem;
    --layout-narrow-part: 16rem;
    --wrapper-max-width: 80rem;
    --wrapper-padding: 2rem;
  }
}

html {
  --background-color: var(--color-white);
  --color: var(--color-dark);
  --color-strong: var(--color-black);

  background-color: var(--background-color);
  color: var(--color);
  font-size: 62.5%;
  font-size: calc(.5 * 62.5% + .5 * (100vw / 144));
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
  scroll-padding: calc(2 * var(--gap-vertical));

  @media (prefers-color-scheme: dark) {
    --background-color: var(--color-dark);
    --color: var(--color-light);
    --color-strong: var(--color-white);
  }
}

body {
  font-size: var(--font-size-default);
  font-family: var(--font-family-default);
  line-height: var(--line-height-default);
}

img {
  max-width: 100%;
  height: auto;
}

strong {
  font-weight: bold;
}

button {
  display: inline-block;
  width: fit-content;
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  color: inherit;
  font: inherit;
}

.visually-hidden {
  @include visually-hidden;
}
