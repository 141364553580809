.copy {
  p:not(:last-child) {
    margin-bottom: 1em;
  }

  // .link {
  //   --link-translateX: 0;
  //   --link-translateY: -.2em;
  //   margin-left: 0;
  //   padding-left: 0;

  //   &:before {
  //     content: none;
  //   }
  // }
}
