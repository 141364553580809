:root {
  --animation-opacity-from: 0;
  --animation-translateX-from: -1em;
  --animation-translateY-from: 1em;
  --animation-scale-from: 1.1;
  --animation-scaleX-from: 0;
}

@keyframes opacity {
  from {
    opacity: var(--animation-opacity-from)
  }
}

@keyframes translateX {
  from {
    transform: translateX(var(--animation-translateX-from))
  }
}

@keyframes translateY {
  from {
    transform: translateY(var(--animation-translateY-from))
  }
}

@keyframes scale {
  from {
    transform: scale(var(--animation-scale-from))
  }
}

@keyframes scaleX {
  from {
    transform: scaleX(var(--animation-scaleX-from))
  }
}
