[class*="heading--"] {
  font-weight: bold;
  color: var(--color-strong);
}

.heading--1 {
  font-size: var(--font-size-heading-1);
  text-transform: uppercase;
  line-height: var(--line-height-heading-1);
}

.heading--2 {
  display: flex;
  align-items: center;
  font-size: var(--font-size-heading-2);
  text-transform: uppercase;
  line-height: var(--line-height-heading-2);
  overflow: hidden;

  &__text {
    --animation-translateY-from: 100%;

    display: inline-block;
    animation: translateY var(--duration-default) var(--ease-out) var(--animation-delay) backwards;
    will-change: transform;
  }

  &:after {
    content: '';
    flex: 1;
    height: .1em;
    min-height: 1px;
    margin-left: 1.2em;
    background-color: currentColor;
    animation: scaleX var(--duration-slow) var(--ease-out) var(--animation-delay) backwards;
    transform-origin: left;
    will-change: transform;
  }
}
