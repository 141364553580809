.nav {
  --animation-delay: calc(var(--duration-slow) + var(--duration-fast));

  grid-area: 2 / 2 / auto / -1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: sticky;
  top: var(--gap-vertical);
  animation: opacity var(--duration-default) var(--animation-delay) backwards;
  will-change: opacity;

  @media #{$breakpoint-tablet} {
    @include visually-hidden;
    grid-area: auto;
  }

  .link {
    translate: calc(
      sin(clamp(0, 2.5 * (var(--nav-factor) - var(--nav-link-index)) + 1.5, 3.14159)) * var(--link-marker-width)
    ) 0;
    will-change: translate;

    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        --nav-link-index: #{$i - 1};
      }
    }
  }
}
