.intro {
  --gap-vertical: 1rem;
  grid-column: 1 / -1;

  @media screen and (max-width: 767px) {
    --gap-vertical: 3rem;
  }

  &__heading,
  .copy {
    grid-column-start: 2;
    max-width: var(--layout-wide-part);
  }

  &__heading {
    --animation-translateY-from: 5rem;

    align-self: end;
    animation-name: opacity, translateY;
    animation-duration: var(--duration-default);
    animation-delay: 0ms, var(--duration-default);
    animation-fill-mode: backwards;
    animation-timing-function: ease, var(--ease-in-out);
    will-change: opacity, transform;

    @media screen and (max-width: 1023px) {
      animation-name: opacity;
      will-change: opacity;
    }

    @media screen and (max-width: 767px) {
      grid-column: span 2;
    }
  }

  .copy {
    animation-name: opacity, translateY;
    animation-delay: calc(var(--duration-fast) + var(--duration-default));
    animation-duration: var(--duration-default);
    animation-fill-mode: backwards;
    will-change: opacity, transform;

    @media screen and (max-width: 1023px) {
      animation-name: opacity;
      will-change: opacity;
    }
  }

  .photo {
    position: relative;
    grid-row: -1 / span 2;

    @media screen and (max-width: 767px) {
      grid-area: auto;
    }

    &__img {
      --animation-translateX-from: -25%;
      animation-name: opacity, translateX;
      animation-delay: var(--duration-default);
      animation-duration: var(--duration-default);
      animation-fill-mode: backwards;
      animation-timing-function: var(--ease-in-out);
      will-change: opacity, transform;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: -.5em;
      background-color: var(--background-color);
      transform-origin: left;
      animation: intro-photo-after var(--duration-slow) var(--ease-in-out) both;
      will-change: transform;
    }
  }
}

@keyframes intro-photo-after {
  from {
    transform: scaleX(0);
  }
  50% {
    transform: scaleX(1);
  }
  to {
    transform: translateX(100%);
  }
}
