:root {
  --wrapper-max-width: 120rem;
  --wrapper-padding: 3rem;
}

.wrapper {
  width: 100%;
  max-width: calc(var(--wrapper-max-width) + 2 * var(--wrapper-padding));
  margin: 0 auto;
  padding-left: var(--wrapper-padding);
  padding-right: var(--wrapper-padding);
}
