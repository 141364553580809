.changelog {
  --animation-delay: calc(var(--duration-fast) + var(--duration-default));

  &__list,
  &__button {
    animation: opacity var(--duration-slow) var(--animation-delay) backwards;
  }

  &__list {
    --gap-vertical: .25em;
    will-change: opacity;

    @media #{$breakpoint-tablet} {
      --gap-vertical: .5em;
    }

    @media #{$breakpoint-tablet} {
      --gap-vertical: 1em;
    }
  }

  &__button {
    position: relative;
    cursor: pointer;
    text-transform: lowercase;
    text-underline-offset: 0.2em;

    @media (hover: hover) {
      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }

    &:before {
      content: '>';
      display: inline-block;
      margin-right: 1ch;
      text-decoration: none;
    }
  }
}
