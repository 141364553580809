.footer {
  --animation-delay: calc(var(--duration-slow) + var(--duration-fast));

  padding-top: 1rem;
  padding-bottom: var(--gap-vertical);

  .wrapper {
    @media #{$breakpoint-tablet} {
      grid-template-columns: auto;
    }
  }

  &__content {
    grid-column-end: -1;
    animation: opacity var(--duration-default) var(--animation-delay) backwards;
    will-change: opacity;
  }
}
