.project {
  --animation-delay: calc(var(--duration-fast) + var(--duration-default));

  &__title {
    grid-column: 1 / -1;
  }

  &__info {
    animation-name: opacity, translateY;
    animation-duration: var(--duration-default);
    animation-delay: var(--animation-delay);
    animation-fill-mode: backwards;
    will-change: opacity, transform;

    @media screen and (max-width: 1023px) {
      animation-name: opacity;
      will-change: opacity;
    }
  }

  .copy {
    animation: opacity var(--duration-slow) var(--ease-out) var(--animation-delay) backwards;
    will-change: opacity;
  }

  .photo {
    position: relative;
    grid-column: 1/-1;
    max-width: 100%;
    aspect-ratio: 16/9;
    border-radius: 1rem;
    box-shadow: 0 1rem 3rem color-mix(in srgb, transparent, 10% currentColor);
    overflow: hidden;
    
    &__img {
      transform-origin: top;
      animation: opacity, scale;
      animation-delay: var(--animation-delay);
      animation-duration: var(--duration-slow);
      animation-timing-function: ease, var(--ease-out);
      animation-fill-mode: backwards;
      will-change: opacity, transform;
    }
  }
}
