.commit {
  display: flex;
  align-items: flex-end;

  @media #{$breakpoint-tablet} {
    flex-wrap: wrap;
  }

  &__message {
    font-size: var(--font-size-larger);

    @media #{$breakpoint-tablet} {
      width: 100%;
    }
  }

  &__date {
    flex: none;
    order: 10;
  }

  &:after {
    content: '';
    flex: 1;
    min-width: 2em;
    height: calc(var(--line-height-default) * 1em);
    margin: 0 1em;
    background-image: linear-gradient(to right, currentColor .1em, transparent .1em);
    background-position: left center;
    background-repeat: repeat-x;
    background-size: .5em .1em;

    @media #{$breakpoint-tablet} {
      content: none;
    }
  }
}
