@import "./scss/_reset.scss";
@import "./scss/_variables.scss";
@import "./scss/_mixins.scss";
@import "./scss/_keyframes.scss";
@import "./scss/_base.scss";
@import "./scss/_layout.scss";
@import "./scss/_typography.scss";

// COMPONENTS
@import "./scss/components/_changelog.scss";
@import "./scss/components/_commit.scss";
@import "./scss/components/_copy.scss";
@import "./scss/components/_footer.scss";
@import "./scss/components/_header.scss";
@import "./scss/components/_intro.scss";
@import "./scss/components/_loading-animation.scss";
@import "./scss/components/_link.scss";
@import "./scss/components/_main.scss";
@import "./scss/components/_nav.scss";
@import "./scss/components/_photo.scss";
@import "./scss/components/_project.scss";
@import "./scss/components/_wrapper.scss";
