.header {
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  overflow-x: hidden;

  &__title {
    --width: 50rem;
    --animation-translateX-from: 100%;

    $text-shadow: '';
    @for $index from 1 through 10 {
      @if $index != 5 {
        $text-shadow: $text-shadow + calc((#{$index - 5}) * var(--width)) + ' 0 currentColor #{ if($index != 10, ',', '') }';
      }
    }

    width: var(--width);
    animation: translateX 10s linear infinite;
    color: var(--color-strong);
    font-weight: bold;
    letter-spacing: .05em;
    text-shadow: unquote($text-shadow);
    text-transform: uppercase;
    will-change: transform;

    @media #{$breakpoint-tablet} {
      --width: 35rem;
      animation-duration: 7s;
    }
  }
}
